import React, {useState, useContext, useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import './CustomLoaderMenu.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ColorPicker from '../colorPicker/ColorPicker';
import InputFileDragAndDrop from '../inputFileDragAndDrop/InputFileDragAndDrop'
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import {
    FontSizeOutlined,
    FolderOutlined,
    ClockCircleOutlined,
    DownloadOutlined,
    PictureOutlined
  } from '@ant-design/icons';
import AlertDialog from '../general/AlertDialog';
import { Checkbox, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function CustomLoaderMenu(props) {

    const t = useTranslate();
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })
    const {iconColor, setIconColor, 
           textColor, setTextColor, 
           loadingBarColor, setLoadingBarColor, 
           backgroundColor, setBackgroundColor,
           isGrid, setIsGridActive,
           isGradiant, setIsGradiantActive,
           backgroundGradiantColor, setBackgroundGradiantColor,
           logoFile, setLogoFile,
           defaultLogo,setDefaultLogo,
           backgroundFile, setBackgroundFile,
           backgroundLocal,setBackgroundLocal,
           backgroundURL, setBackgroundURL,
           logoURL, setLogoURL,
           footerLogoThemeColor, setFooterColorThemeColor
        } = useContext(CustomLoaderContext);

    const [icon, setIconOpen] = useState(false);
    const [text, setTextOpen] = useState(false);
    const [logo, setLogoOpen] = useState(false);
    // const [centerLogo, setCenterLogoOpen] = useState(false);
    const [loadingBar, setLoadingBarOpen] = useState(false);
    const [footer, setFooterOpen] = useState(false);
    const [background, setBackgroundOpen] = useState(false);
    const [collapse, setCollapse] = useState(false);

    // footer logo
    const flThemeColors = ["black", "white"];

    const handleChangeFooterThemeColor = (event) => {
        setFooterColorThemeColor(event.target.value);
    };

    const closeAll = () => {
        setIconOpen(false);
        setTextOpen(false);
        setLogoOpen(false);
        setLoadingBarOpen(false);
        setFooterOpen(false);
        setBackgroundOpen(false);
    }
    
    const handleIconClick = () => {
        var status = icon;
        closeAll();
        setIconOpen(!status);
    };

    const handleTextClick = () => {
        var status = text;
        closeAll();
        setTextOpen(!status);
    };

    const handleLoadingBarClick = () => {
        var status = loadingBar;
        closeAll();
        setLoadingBarOpen(!status);
    };

    const handleBackgroundClick = () => {
        var status = background;
        closeAll();
        setBackgroundOpen(!status);
    };

    const handleLogoClick = () => {
        var status = logo;
        closeAll();
        setLogoOpen(!status);
    };

    const handleFooterClick = () => {
        var status = footer;
        closeAll();
        setFooterOpen(!status);
    };

    const handleIconColor = (color) => {
        setIconColor(color);
    }

    const handleTextColor = (color) => {
        setTextColor(color);
    }

    const handleLoadingBarColor = (color) => {
        setLoadingBarColor(color);
    }

    const handleBackgroundColor = (color) => {
        setBackgroundColor(color);
    }

    const handleGrid = () => {
        setIsGridActive(!isGrid);
    }

    const handleGradiant = () => {
        setIsGradiantActive(!isGradiant);
    }

    const handleGradiantBackgroundColor = (color) => {
        setBackgroundGradiantColor(color);
    }

    const handleBackgroundFile = (file) => {
        setBackgroundFile(file);
    }

    const handleLogoFile = (file) => {
        setLogoFile(file);
    }

    const handleLogoURL = (url) => {
        setLogoURL(url);
    }

    const handleBackgroundURL = (url) => {
        setBackgroundURL(url);
    }
    const handleAlert = () => {
        console.log(" ")
    }
    useEffect(() => {
        if(logoURL){
            setIconColor("#3434E0")
        }
    },[logoURL])
    useEffect(() => {
      if(defaultLogo){
        setLogoFile('');
        setLogoURL('');
      }
    },[defaultLogo])
    return (
        <div className={collapse ? 'sidebar-container collapsed' : 'sidebar-container'}>
            
            <List>                
                <ListItem button onClick={handleLogoClick} className={logo ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <FolderOutlined src={`/images/reports-hovered.svg`} style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.header')} />
                </ListItem>
                <Collapse in={logo} unmountOnExit>
                    <ListItem disabled={!defaultLogo}>
                        <ColorPicker disabled={!defaultLogo} defaultColor={iconColor} handleColor={(color)=>handleIconColor(color)}></ColorPicker>
                    </ListItem>
                    <ListItem>
                        <InputFileDragAndDrop id="logoFile" setLogoURL={setLogoURL} setOpen={setOpen} handleURL={(url)=>handleLogoURL(url)} logoFile={logoFile} setLogoFile={setLogoFile} defaultFile={defaultLogo ? "" :logoURL} handleFile={(file)=>handleLogoFile(file)}></InputFileDragAndDrop>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleTextClick} className={text ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <FontSizeOutlined style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }} />
                    <ListItemText primary={t('customLoaderSidebar.menu.textColor')} />
                </ListItem>
                <Collapse in={text}>
                    <ListItem>
                        <ColorPicker defaultColor={textColor} handleColor={(color)=>handleTextColor(color)}></ColorPicker>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleLoadingBarClick} className={loadingBar ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <ClockCircleOutlined style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.loadingBar')} />
                </ListItem>
                <Collapse in={loadingBar}>
                    <ListItem>
                        <ColorPicker defaultColor={loadingBarColor} handleColor={(color)=>handleLoadingBarColor(color)}></ColorPicker>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleFooterClick} className={footer ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <DownloadOutlined style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.footer')} />
                </ListItem>
                <Collapse in={footer} className='footer-container'>
                    <ListItem className="text-field">
                        <select
                            className="select-footer-theme-color"
                            value={footerLogoThemeColor}
                            onChange={handleChangeFooterThemeColor}
                            label=" "
                           
                        >
                            {
                                flThemeColors.map((l) => {
                                    return <option key={`theme-${l}`} value={l}>{t(`customLoaderSidebar.menu.${l}`)}</option>
                                    
                                })
                            }
                        </select>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleBackgroundClick} className={background ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.background')} />
                </ListItem>
                <Collapse in={background} unmountOnExit>
                    <ListItem>
                        <div className='background-item-container'>
                            <ColorPicker disabled={!!backgroundFile} defaultColor={backgroundColor} handleColor={(color)=>handleBackgroundColor(color)}></ColorPicker>

                            {isGradiant && <ColorPicker disabled={!!backgroundFile} defaultColor={backgroundGradiantColor} handleColor={(color)=>handleGradiantBackgroundColor(color)}></ColorPicker>}

                            <div>
                                <Checkbox disabled={!!backgroundFile} checked={isGradiant} onChange={handleGradiant}/> {t('customLoaderSidebar.menu.gradiant')}
                            </div>
                            <div>
                                <Checkbox disabled={!!backgroundFile} checked={isGrid} onChange={handleGrid}/>  {t('customLoaderSidebar.menu.grid')}                            
                            </div>
                            <InputFileDragAndDrop setOpen={setOpen} id="backgroundFile" handleURL={(url)=>handleBackgroundURL(url)} setBackgroundFile={setBackgroundFile} handleFile={(file)=>handleBackgroundFile(file)} defaultFile={backgroundLocal} backgroundFile={backgroundFile}></InputFileDragAndDrop>
                        </div>
                    </ListItem>
                </Collapse>
            </List>    
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={handleAlert}
                buttonMessage='OK'
            />     
        </div>
    );
}

export default CustomLoaderMenu;
