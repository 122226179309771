import React, { useEffect, useContext, Fragment, useReducer, useState } from 'react';
import CustomLoaderPreview from '../../components/CustomLoaderPreview';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import './CustomLoaderEditor.css';
import CustomLoaderService from '../../services/CustomLoader';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import { useTranslate } from 'react-polyglot';
import Loading from '../../components/loading';
import {Button} from '@mui/material'
import Notification from '../../components/notification/notification';
import AlertDialog from '../../components/general/AlertDialog';
function CustomLoaderEditor() {

    const { showLoading, setShowLoading } = useContext(ShowLoadingContext)
    const campaignId = window.location.pathname.split('/')[3];
    const t = useTranslate();
    const [open,setOpen] = useState({
        title : "",
        subtitle: "",
        isOpen: false
    })
    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => 
        ({ ...prevState, ...currState }),
        { showNotification: false, 
        propsNotification: {} 
    });
    const {
        iconColor,setIconColor, 
        textColor,setTextColor, 
        loadingBarColor,setLoadingBarColor, 
        backgroundColor,setBackgroundColor,
        backgroundGradiantColor,setBackgroundGradiantColor,
        isGrid,setIsGridActive,
        isGradiant,setIsGradiantActive,
        // footerColor,setFooterColor,
        // footerText,setFooterText,
        // footerTextBold, setFooterTextBold,
        // footerTextEn, setFooterTextEn,
        // footerTextBoldEn, setFooterTextBoldEn,
        logoFile, setLogoURL,logoURL,
        backgroundFile, setBackgroundFile,
        backgroundLocal, setBackgroundLocal,
        resetBackground, setResetBackground,
        defaultLogo,setDefaultLogo,
        resetLogo, setResetLogo } = useContext(CustomLoaderContext);

    useEffect( () => {
        getCustomLoaderConfig();
    }, []);

    async function getCustomLoaderConfig() {
        setShowLoading(true);
        (CustomLoaderService.getCustomLoaderConfig(campaignId))
        .then(response=> response.json())
        .then(data => {
            setData(data);
        });
        
    }

    async function discardChanges() {
        setShowLoading(true);
        (CustomLoaderService.applyInitialConfiguration(campaignId))
        .then(response=> response.json())
        .then(data => {
            setData(data);
            setBackgroundFile(undefined)
        });
    }


    const openDialog = () => {
        setOpen({
            title: t('customLoaderLayout.discard'),
            subtitle: t('customLoaderLayout.discardMessage'),
            isOpen: true
        })
    }

    const setData = (jsonData) => {
        setIconColor(jsonData.iconColor == "null" ? "#3434E0" : jsonData.iconColor);
        setTextColor(jsonData.textColor);
        setLoadingBarColor(jsonData.loadingBarColor);
        setBackgroundColor(jsonData.backgroundColor);
        setBackgroundGradiantColor(jsonData.backgroundGradiantColor);
        setIsGridActive(jsonData.isGrid == 'true' ? true : false );
        setIsGradiantActive(jsonData.isGradiant == 'true' ? true : false );
        setLogoURL(jsonData.logoURL);
        setBackgroundLocal(jsonData.backgroundURL);
        setResetLogo(false);
        setResetBackground(false);
        setShowLoading(false);
        setDefaultLogo(jsonData.hasDefaultLogo == "true" || jsonData.hasDefaultLogo == true ? true : false)
    }

    function closeNotification() {
        setNotification({ showNotification: false });
    }

    const save = async() => {
        setShowLoading(true)
        let formData = new FormData();
        formData.append("isUpdate", true);
        formData.append("iconColor", defaultLogo == true ? iconColor : "null");
        formData.append("textColor", textColor);    
        formData.append("loadingBarColor", loadingBarColor);
        formData.append("backgroundColor", backgroundColor);
        formData.append("footerColor", '#FFFFFF');
        formData.append("isGrid", isGrid);
        formData.append("isGradiant", isGradiant);
        formData.append("backgroundGradiantColor", backgroundGradiantColor);
        formData.append("footerText", '');
        formData.append("footerTextBold", '');
        formData.append("footerTextEn", '');
        formData.append("footerTextBoldEn", '');
        formData.append("logoFile", logoFile);
        formData.append("backgroundFile", backgroundFile);
        formData.append("resetBackground", resetBackground);
        formData.append("hasBackgroundURL", backgroundLocal != '' && backgroundLocal !== undefined);
        formData.append("hasLogoURL", logoFile != '' && logoFile !== undefined);
        formData.append("hasDefaultLogo", defaultLogo);
        let rta = await CustomLoaderService.updateCustomLoaderConfig(campaignId, formData);
        setShowLoading(false)
        if(rta.status == 200){
            setNotification({ showNotification: true, propsNotification: { label: "OK", message:t('customLoaderLayout.applyChanges') } })
        }
        else{
            setNotification({ showNotification: true, propsNotification: { label: "Error", message:t('customLoaderLayout.errorChanges') } })
        }
    }
    
    return (
        <Fragment>
            {showLoading && 
                <Loading/>
            }
            <div className="preview-container">
                <CustomLoaderPreview className="preview"/>
                <div className='buttons-container'>
                    <div className='apply-changes-button'>
                        <button className='save-button' variant='contained' onClick={save}> {t('customLoaderLayout.apply')}</button>
                    </div>
                    <button className='discard-changes-button' size='small' variant='outlined' onClick={openDialog}>{t('customLoaderLayout.discard')}</button>
                </div>
            </div>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={discardChanges}
                buttonMessage={t("customLoaderLayout.yes")}
            />
            {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
        </Fragment>
    );
}

export default CustomLoaderEditor;