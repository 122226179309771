import React, {createContext, useEffect, useState} from 'react';

export const CustomLoaderContext = createContext();

const CustomLoaderProvider = (props) => {

    const [iconColor, setIconColor] = useState("#3434E0"); 
    const [textColor, setTextColor] = useState("#3434E0"); 
    const [loadingBarColor, setLoadingBarColor] = useState("#3434E0"); 
    const [backgroundColor, setBackgroundColor] = useState("#ffffff"); 
    const [isGrid, setIsGridActive] = useState(true); 
    const [isGradiant, setIsGradiantActive] = useState(false);
    const [backgroundGradiantColor, setBackgroundGradiantColor] = useState("#ffffff"); 
    const [footerLogoThemeColor, setFooterColorThemeColor] = useState("black");
    const [logoFile, setLogoFile] = useState("undefined");
    const [backgroundFile, setBackgroundFile] = useState(); 
    const [backgroundLocal, setBackgroundLocal] = useState("undefined"); 
    const [backgroundURL, setBackgroundURL] = useState("/images/icon_360.svg");
    const [logoURL, setLogoURL] = useState("");
    const [resetLogo, setResetLogo] = useState(false);
    const [resetBackground, setResetBackground] = useState(false);
    const [defaultLogo, setDefaultLogo] = useState(null)
    return (<CustomLoaderContext.Provider
        value={{iconColor, setIconColor,
                textColor, setTextColor,
                loadingBarColor, setLoadingBarColor,
                backgroundColor, setBackgroundColor,
                isGrid, setIsGridActive,
                isGradiant, setIsGradiantActive,
                backgroundGradiantColor, setBackgroundGradiantColor,
                logoFile, setLogoFile,
                defaultLogo,setDefaultLogo,
                backgroundFile, setBackgroundFile,
                backgroundLocal,setBackgroundLocal,
                backgroundURL, setBackgroundURL,
                logoURL, setLogoURL,
                resetLogo, setResetLogo,
                resetBackground, setResetBackground,
                footerLogoThemeColor, setFooterColorThemeColor,
                }}>
            {props.children}
        </CustomLoaderContext.Provider>
    );
};

export default CustomLoaderProvider;
